import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthorize } from '../../utils/isLogin'
//import jwtDecode from 'jwt-decode'

const loginGuard = ({ component: Component, path }) => {
  
  return (
    //    <Route to={path} component={Component} />
    
    <Route
      to={path}
      render={routeProps => {
        // console.log(routeProps.location.pathname)
        const user = localStorage.getItem("userLogin")
        if (user) {
          const company = JSON.parse(user)['company']
          if (company === 'vietfarm') {
            //const decoded = jwtDecode(localStorage.jwtToken)
            const pathname = routeProps.location.pathname
            //const username = decoded.username
            // console.log(isAuthorize(pathname))
            // console.log(pathname)
            if (isAuthorize(pathname)) {
              return <Component {...routeProps} />;
            }
            else {
              alert('Bạn không có quyền truy cập !')
              return <Redirect to={"/task"} />
            }
          }
        }
        alert('Vui lòng đăng nhập !')
        return <Redirect to="/login" />
      }}
    />
  );
};

export default loginGuard;
