import { LOGIN_USER, LOGOUT_USER } from "../constants/user";
import { isEmpty } from "validator";

let initialState = {
  userProfile: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const updateState = { ...state };
      updateState.userProfile = action.payload;
      updateState.isAuthenticated = !isEmpty(action.payload.username);
      return updateState;
    }
    case LOGOUT_USER: {
      return {
        ...state,
        userProfile: {},
        isAuthenticated: false
      };
    }
    default:
      return state;
  }
};

export default userReducer;