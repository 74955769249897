// export const SERVER = "http://10.148.13.2:5001";
// export const SERVER = "http://61.28.224.196:5001";
// export const SERVER = "http://vfhr.gcfood.vn:5001";
//export const ROUTE = "/hoasen"
//export const SERVER = "https://mautic.gbsmarter.com:5002";
// export const SERVER = "http://35.198.228.251:5001";
// export const SERVER = "http://172.30.1.26:5001";
// export const SERVER = "http://35.197.137.55:5001";
// export const SERVER = "http://0.0.0.0:5001";
// export const SERVER = "http://localhost:5001";
// export const IP = '10.148.13.2'
// export const IP = "61.28.224.196";
// export const IP = "vfhr.gcfood.vn";
//export const IP = "mautic.gbsmarter.com";
export const ROUTE = "/hoasen"
// export const SERVER = "http://113.163.216.208:5002";
const current_url = window.location.href;
const http_ = current_url.split('/')[2];
export const IP = http_.split(':')[0];
export const SERVER = "https://"+ IP + "/api";
// export const IP = '35.198.228.251';
// export const IP = '35.197.137.55';
// export const IP = '172.30.1.26';
// export const SERVER = "http://35.198.228.251:5001";
// export const SERVER = "https://hass.troly.ai";
// export const SERVER_FARM = "https://aun.uel.edu.vn";
// export const SERVER = "http://34.87.176.114:5016";
// export const SERVER = "https://wpujde3w61.execute-api.ap-southeast-1.amazonaws.com/dev"
// export const SERVER_VIDEO = "http://34.87.176.114:5016"
